import React, { useEffect } from 'react'
import PageHead from './PageHead'
import '../css/about.css'
import firstImage from '../images/FIrst_image.jpg'
import secondImage from '../images/about1.jpg'
import thirdImage from '../images/about2.jpg'
import fourthImage from '../images/Aboutus.jpg'
import about1 from '../images/about-1.png'
import about2 from '../images/about-2.png'
import about3 from '../images/about-3.png'
import about4 from '../images/about-4.png'
import about5 from '../images/about-5.png'
import about6 from '../images/about-6.png'


const About = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div>
      <PageHead page='About Us' content='Prolumin is a system designed and built around the best hardware and Aluminium section components' image={firstImage} />
      <div className="aboutUs">
        {/* <div className="aboutUs-image">
            <img src={firstImage} alt="..." />
        </div> */}

        <div className="page-header">
            <div className="page-header_intro">
                <h2 style={{fontSize : '22px'}}>We, at PROLUMIN ARCHITECTURAL SOLUTIONS, are a GERMAN system supplier and a "ONE STOP SHOP" for
                    all your Architectural Aluminium Door & Window needs. </h2>
                <p>PROLUMIN is a system designed and built around the best hardware and Aluminium section components
                    available in the market. It is a Euro-groove system with tropicalized features and functionality.
                    Euro-groove design allows diversity in hardware and still maintains full inter-system compatibility.
                    Most of our systems allow for double and triple sealing in order to provide for excellent noise
                    reduction and protection against adverse weather conditions.</p>
            </div>
        </div>

        <div className="content">

            <div className="layout layout--onecol">
                <div className="layout_region layout_region--content">
                    <section className="block">
                        <header className="block---image_introduction">
                            <h2>Unsere Mission</h2>
                            <p className="h3">Together we are improving the living and working environment for people now
                                and for future generations.</p>
                        </header>
                        <div className="block--image_image1">
                            <div className="media">
                                <img src={secondImage} alt="..." />
                            </div>
                        </div>
                        <div className="block--image_image2">
                            <div className="box">
                                <div className="media">
                                    <img src={thirdImage} alt="..." />
                                </div>
                            </div>
                        </div>
                        <aside className="block--image_text">
                            <div className="h3">
                                <div className="field h3">
                                    We combine design, technology and digitalisation with innovative solutions that add
                                    value and encourage our partners to create sustainable buildings.
                                </div>
                            </div>
                        </aside>
                    </section>
                </div>
            </div>
            <div className="layout layout--onecol row p-4">
                <div className="about col-md-6">
                    <img className="w-100" src={fourthImage} alt="..." />
                </div>
                <div className="col-md-6">
                    <div className="d-flex flex-column align-items-start" style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about1} alt="..." />
                    </div>
                    <div className="d-flex flex-column align-items-center" style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about2} alt="..." />
                    </div>
                    <div className="d-flex flex-column align-items-end"  style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about3} alt="..." />
                    </div>
                    <div className="d-flex flex-column align-items-start"  style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about4} alt="..." />
                    </div>
                    <div className="d-flex flex-column align-items-center"  style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about5} alt="..." />
                    </div>
                    <div className="d-flex flex-column align-items-end"  style={{margin : 0}}>
                        <img className="row col-4 col-md-4 p-1" src={about6} alt="..." /> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default About
