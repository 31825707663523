import casementWindow from '../images/Casement-window.jpg'
import casement30 from '../images/casement_30.jpg'
import casement32 from '../images/casement_32.jpg'
import casement40 from '../images/casement_40.jpg'
import casement50 from '../images/casement_50.jpg'
import pivotWindows from '../images/pivot windows.jpg'
import pivotWindowsFeature from '../images/PivotFeature.jpg'
import tiltWindows from '../images/tilt&turn.jpg'
import tiltWindowsFeature from '../images/tilt-feature.jpg'
import slidingWindows from '../images/Sliding Windows.jpg'
import sliding22 from '../images/sliding_22.jpg'
import sliding25 from '../images/sliding_25.jpg'
import sliding26 from '../images/sliding_26.jpg'
import sliding27 from '../images/sliding_27.jpg'
import sliding29 from '../images/sliding_29.jpg'
import sliding37 from '../images/sliding_37.jpg'
import tiltSlide from '../images/Tilt&Slide.jpg'
import tiltSlideFeature from '../images/tilt&slide_feature.jpg'
import casementdoor from '../images/Casement Door.jpg'
import casementdoor40 from '../images/casement-door_40.jpg'
import casementdoor50 from '../images/casement-door_50.jpg'
import osdoor from '../images/OS_operator.jpg'
import osdoorFeature from '../images/os_feature.jpg'
import curtainWall from '../images/Curtain Wall.jpg'
import curtainWall45 from '../images/curtain_45.jpg'
import curtainWall50 from '../images/curtain_50.jpg'
import curtainWall57 from '../images/curtain_57.jpg'
import semiUtilized from '../images/Semi_Utilized.jpg'
import semiUtilizedFeature from '../images/semi-feature.jpg'

const productWindow = [
    {
        key : 1,
        image : casementWindow,
        name : 'Casement Window',
        desc : 'We offer a comprehensive solution for every type of opening with quality casement windows designed to meet tropical weather conditions. Our casement windows are designed to ensure proper water drainage and minimum air leakage, keeping in mind the comfort and convenience of the end user. These windows feature excellent sealing characteristics and the option of Double Glass units, providing effective sound suppression while reducing heat transfer and contributing to significant energy savings over time.',
        buttons : ['30 mm', '32 mm', '40 mm', '50 mm'],
        productFeatures : [
            {
                featureImage : casement30,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '30 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '4 mm To 6 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '73.4 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '44.4 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1530 mm'
                    }
                ]
            },
            {
                featureImage : casement32,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '32 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '4 mm To 6 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '76.4 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '40 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1650 mm'
                    }
                ]
            },
            {
                featureImage : casement40,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '40 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 28 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '72.3 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '39 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1830 mm'
                    }
                ]
            },
            {
                featureImage : casement50,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '97.5 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '46 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2100 mm'
                    }
                ]
            },
        ],
    },
    {
        key : 2,
        image : pivotWindows,
        name : 'Pivot Window',
        desc : 'Pivot windows offer a solution for openings which have a constraint of space or for just pure aesthetics. The sash opens 90° to 180°, either vertically or horizontally. Designed around world-class hardware. These windows however cannot be equipped with filter screens.',
        buttons : [],
        productFeatures : [
            {
                featureImage : pivotWindowsFeature,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '166.50 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '74 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2200 mm'
                    },
                    {
                        featureName : 'Window Width (Max.)',
                        featureValue : '1600 mm'
                    },
                    {
                        featureName : 'Window Weight (Max.)',
                        featureValue : '100 Kg. (Aluminium + Glass)'
                    },
                ]
            }
        ],
    },
    {
        key : 3,
        image : tiltWindows,
        name : 'Tilt & Turn Window',
        desc : 'Tilt and Turn windows offer great flexibility to our more discerning clients. It offers full opening akin to casement windows for needs of cleaning and greater access. It also offers greater security by allowing superb ventilation using the tilt option and yet not allowing full access. It has better insulation characteristics than sliding options as well.',
        buttons : [],
        productFeatures : [
            {
                featureImage : tiltWindowsFeature,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '81.0 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '46 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1800 mm'
                    }
                ]
            }
        ],
    },
]

const productSliding = [
    {
        key : 1,
        name : 'Sliding Window',
        desc : 'We give solutions to cover every type of opening with quality sliding windows designed for tropical conditions keeping in mind water drainage and minimum air leakage. Our sliding window systems are designed for easy operation with a sturdy construction that gives a long and useful life. Excellent sealing characteristics and the option of Double Glass units offer sound suppression with very low heat transfer across the windows which in turn give you excellent power savings over time. Designed for Ease of Fabrication with maximum on-floor productivity, these windows are the ideal fit for every quality-conscious fabricator. The Euro groove system gives the end user full flexibility over the type of hardware being used.',
        image : slidingWindows,
        buttons : ['22 mm', '25 mm', '26 mm Sleek', '27 mm', '29 mm/ 29 mm Sleek', '37 mm/ 37 mm Sleek'],
        productFeatures : [
            {
                featureImage : sliding22,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '22 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single Point Lock'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : 'Sealing with wool pile'
                    },
                    {
                        featureName : 'S Glass Range',
                        featureValue : '4 mm To 6 mm'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '82 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '54 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '84 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1530 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
            {
                featureImage : sliding25,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '25 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : ' 8 mm (Sealing with wool pile)'
                    },
                    {
                        featureName : 'S Glass Range',
                        featureValue : '4 mm To 6 mm'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '95 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '86 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2100 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
            {
                featureImage : sliding26,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '26 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single Point Lock'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '90 degree'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '9 mm(Sealing with wool pile)'
                    },
                    {
                        featureName : 'S Glass Range',
                        featureValue : '4 mm To 6 mm'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '87 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '86 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '1830 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
            {
                featureImage : sliding27,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '27 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Champion Plus'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '11 mm (Sealing with wool pile)'
                    },
                    {
                        featureName : 'Single Glass Range',
                        featureValue : '4/5/6 mm'
                    },
                    {
                        featureName : 'Double Glass Range',
                        featureValue : '18 mm'
                    },
                    {
                        featureName : 'Laminated Glass',
                        featureValue : 'NA'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '99 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '40 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '75 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2135 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
            {
                featureImage : sliding29,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '29 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '8 mm (Sealing with wool pile)'
                    },
                    {
                        featureName : 'S Glass Range',
                        featureValue : '5 mm To 20 mm'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '104 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '86 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2440 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
            {
                featureImage : sliding37,
                features : [
                    {
                        featureName : 'Shutter Depth',
                        featureValue : '37 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '9 mm (Sealing with wool pile)'
                    },
                    {
                        featureName : 'S Glass Range',
                        featureValue : '6 mm To 24 mm'
                    },
                    {
                        featureName : 'Sightline with Shutter',
                        featureValue : '124 mm'
                    },
                    {
                        featureName : '2 Track Depth',
                        featureValue : '61 mm'
                    },
                    {
                        featureName : '3 Track Depth',
                        featureValue : '110 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '3010 mm'
                    },
                    {
                        featureName : 'Finishes',
                        featureValue : 'Powder/Wood/Anodizing'
                    }
                ]
            },
        ],
    },
    {
        key : 2,
        name : 'Tilt & Slide Window',
        desc : 'Tilt and Slide windows are a twist on conventional slider windows. Both sashes slide independently and also offer a tilt option for providing ventilation while controlling access thus providing enhanced security.',
        image : tiltSlide,
        buttons : [],
        productFeatures : [
            {
                featureImage : tiltSlideFeature,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '142 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '74 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2440 mm'
                    }
                ]
            }
        ],
    },
]
const productFacade = [
    {
        key : 1,
        name : 'Curtain Wall',
        desc : 'Curtain Wall systems. Our systems offer greater flexibility for mating different size profiles. Fully compatible opening options like ventilators and other openings are given, enhancing utility without compromising aesthetics. Both SGU and DGU options with world-class hardware offer high weather and wind resistance for the system. Higher Glass to frame ratios offers full visibility without compromising on the design characteristics.',
        image : curtainWall,
        buttons : ['45 mm', '50 mm', '57 mm'],
        productFeatures : [
            {
                featureImage :curtainWall45,
                features : [
                    {
                        featureName : 'Mullion/Transom Depth',
                        featureValue : '45 mm'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 20 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2440 mm'
                    }
                ]
            },
            {
                featureImage : curtainWall50,
                features : [
                    {
                        featureName : 'Mullion/Transom Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 24 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '3010 mm'
                    }
                ]
            },
            {
                featureImage : curtainWall57,
                features : [
                    {
                        featureName : 'Mullion/Transom Depth',
                        featureValue : '57 mm'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 20 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2440 mm'
                    }
                ]
            }
        ],
    },
    {
        key : 2,
        name : 'Semi Unitized',
        desc : 'Semi-Unitized Glazing systems offer more flexibility to the fabricators to assemble a large part of their curtain walls off-site, thereby saving time and cost. They also provide greater structural efficiency than traditional curtain walls, thus reducing the cost further without compromising on the glass-to-frame ratios.',
        image : semiUtilized,
        buttons : [],
        productFeatures : [
            {
                featureImage : semiUtilizedFeature,
                features : [
                    {
                        featureName : 'Mullion/Transom Depth',
                        featureValue : '67 mm'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 24 mm'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '3010 mm'
                    }
                ]
            }
        ],
    },
]

const productDoor = [
    {
        key : 1,
        name : 'Casement Door',
        desc : 'Double leaf doors with flying mullion and frameless bottom sections with full SGU and DGU compatibility are also available. Excellent Sealing and flexibility with Euro Groove-compatible hardware give fabricators a range of options. The availability of both visible and invisible hinges satisfies both utilitarian and aesthetic needs. Needless to say, these are both sound and thermal transfer-resistant windows and doors.',
        image : casementdoor,
        buttons : ['40 mm', '50 mm'],
        productFeatures : [
            {
                featureImage : casementdoor40,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '40 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 28 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '104.4 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '55.3 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2135 mm'
                    }
                ]
            },
            {
                featureImage : casementdoor50,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '142 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : '74 mm'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2440 mm'
                    }
                ]
            }
        ],
    },
    {
        key : 2,
        name : 'O.S. Operators',
        desc : 'OS Operator for outswing windows offers three options with one solution. Having a screen, limiting the opening and having multiple lock points for better insulation, security and lessening the air/wind/water penetration. Our design is compatible with small openings well.',
        image : osdoor,
        buttons : [],
        productFeatures : [
            {
                featureImage : osdoorFeature,
                features : [
                    {
                        featureName : 'Frame Depth',
                        featureValue : '50 mm'
                    },
                    {
                        featureName : 'Locking Type',
                        featureValue : 'Single & Multi Point Lock with Handle'
                    },
                    {
                        featureName : 'Glass Range',
                        featureValue : '5 mm To 38 mm'
                    },
                    {
                        featureName : 'Openable Sight Line',
                        featureValue : '97.8 mm'
                    },
                    {
                        featureName : 'Fix Sight Line',
                        featureValue : 'NA'
                    },
                    {
                        featureName : 'Corner Details',
                        featureValue : '45 Degree cut with corner connector'
                    },
                    {
                        featureName : 'Sealing Overlap',
                        featureValue : '6 mm (Sealing with Gasket)'
                    },
                    {
                        featureName : 'Window Height (Max.)',
                        featureValue : '2100 mm'
                    }
                ]
            }
        ],
    },
]

export {productWindow, productSliding, productFacade, productDoor}