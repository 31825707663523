import React, { useEffect } from 'react'
import '../css/why-aluminium.css'
import image1 from '../images/aluminium.jpg'
import image2 from '../images/why.jpg'
import image3 from '../images/aluminium-2.jpg'
// import PageHead from './PageHead'

const WhyAluminum = () => {

    useEffect(()=>{
        window.scrollTo(0,0);
      },[])

  return (
    <div className="aluminium">
        {/* <PageHead page='Why Aluminium?' image={image1} /> */}
        <div className="aluminium-image">
            <img src={image1} alt="..." />
        </div>
        <div className="intro">
            <div>
                <h1><span>Aluminium, an all-rounder with countless benefits</span></h1>
                <div className="info">
                    <h5>Some people favour aluminium because of its environmental impact, others are fan of the design
                        variety. Why would you choose aluminium as a&nbsp;material for your new windows and doors? We
                        list ten reasons to make that choice a&nbsp;little easier.</h5>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="layout layout--twocol">
                <div className="layout_region layout--twocol--first">
                    <div>
                        <div className="field">
                            <p>It is no coincidence that aluminium is used for windows, doors, and façades in the most
                                prestigious and iconic new buildings around the world. Aluminium is an abundant material
                                that is infinitely recyclable with no loss of quality. What is more, the
                                industry-leading thermal insulation that aluminium offers helps you to create anything
                                with an eye on energy-efficiency. And that is not all. Explore the main benefits of
                                aluminium below.</p>
                        </div>
                    </div>
                </div>
                <div className="layout_region layout--twocol--second">
                    <div>
                        <div className="media">
                            <img src={image2} alt="..." />
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout layout--onecol">
                <div className="layout_region layout_region--content">
                    <div>
                        <h1 className="h3" id="ten-aluminium-benefits">
                            Ten aluminium benefits
                        </h1>
                        <div className="field">
                            <h4>Long lifetime</h4>
                            <p>Choosing aluminium is a&nbsp;choice for life. Aluminium profiles will remain beautiful
                                for years and years. They are not affected by UV-rays or moisture, and when maintained
                                properly they do not warp, corrode, or rot.</p>
                            <h4>Thermal &amp; acoustic insulation</h4>
                            <p>Aluminium solutions offer excellent thermal insulation. An aluminium window insulates
                                well against both the cold and the heat. Combined with acoustical glass, aluminium
                                guarantees optimal soundproof solutions.</p>
                            <h4>Design that fits</h4>
                            <p>Enjoy creative freedom with all kinds of shapes and styles. Choose from a&nbsp;wide range
                                of design options, from sleek contemporary to rustic historical. Moreover, aluminium
                                profiles come in almost every dimension. There is always a&nbsp;system that meets your
                                needs.</p>
                            <h4>Sustainability is key</h4>
                            <p>Aluminium is 100% recyclable. The recycling process takes a&nbsp;minimum amount of
                                energy, without loss of quality of the material. And think of the sustainable benefit as
                                well: low U-values of our aluminium products contribute to your energy savings and helps
                                to reduce the environmental impact of your project.</p>
                            <h4>Numerous colours</h4>
                            <p>Select your favourite colour and coating option. Aluminium profiles can be anodised or
                                painted in more than 400 RAL colours and
                                different finish types. A&nbsp;functional, renaissance, or minimalistic
                                building? There is an aluminium solution for every type of home.</p>
                        </div>
                    </div>
                    <div className="">
                        <div className="caption_wrapper">
                            <div className="media">
                                <img src={image3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="field">
                            <h4>Versatility</h4>
                            <p>Versatility is aluminium’s second nature. Expansion and contraction by changes in
                                temperature are kept to a&nbsp;minimum, making aluminium the preferred choice for
                                bi-fold and patio doors, as well as any large-size windows. It is an ultra-light yet
                                robust material that can realise complex structures effortlessly.</p>
                            <h4>Safe and optimal fire protection</h4>
                            <p>Aluminium is very strong, non-flammable, and integrates smoothly with technologies and
                                systems that further enhance safety and protection. Most of our systems are
                                burglar-resistant, and some variants even offer fire or smoke-proof solutions.</p>
                            <h4>Easy maintenance</h4>
                            <p>Aluminium windows and doors are very low-maintenance, requiring only a&nbsp;wash with
                                soapy water a&nbsp;couple of times a&nbsp;year. Select from hundreds of hard-wearing,
                                durable colour finishes and rest assured that the aluminium will never need repainting.
                            </p>
                            <h4>Professional placement</h4>
                            <p>The computer-controlled cutting of the profiles and the precise assembly of the finished
                                product enables a&nbsp;smooth and flawless installation of all our aluminium solutions
                            </p>
                            <h4>An investment that pays off</h4>
                            <p>Aluminium improves the overall performance and value of your building. It is an
                                investment that pays off, if you take into account all the benefits. Reynaers Aluminium
                                guarantees that its systems meet the highest technical specifications with
                                a&nbsp;ten-year system guarantee and a&nbsp;five-year guarantee on hardware accessories.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout layout--onecol">
                <div className="layout_region layout_region--content">
                    <div className="">
                        <h2 className="h3" id="state-of-the-art-aluminium">
                            State-of-the art aluminium
                        </h2>
                        <div className="field field--name-field-body field--type-text-long field--label-hidden field__item">
                            <p>Aluminium will not let you down. With a&nbsp;world-class testing and design centre and
                                many years of industry experience, Reynaers Aluminium is proud to be pushing the
                                boundaries of product quality. All our products are tested to exceed both local and
                                international standards, allowing them to be featured in some of the most challenging
                                locations. Experience the difference.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout"></div>
        </div>
    </div>
  )
}

export default WhyAluminum
