import React, { } from "react";
import "../css/home.css";
import lifetime from "../images/lifetime.png";
import insulation from "../images/insulation.png";
import design from "../images/design.png";
import sustainability from "../images/sustainability.png";
import colours from "../images/colours.png";
import versatility from "../images/versatility.png";
import safety from "../images/safety.png";
import investment from "../images/investment.png";
// import ProductSlider from "./ProductSlider";
import productWindow from "../images/Homepage_Window.jpg";
import productDoor from "../images/Homepage_doors.jpg";
import productBenchMark from "../images/Homepage_Benchmark.jpg";
import window from '../images/window.png'
import door from '../images/door.png'
import facade from '../images/facade.png'
import slidingFolding from '../images/sliding and folding.png'
import MoreProducts from '../images/more products.png'
import detail1 from '../images/detail1.png'
import detail2 from '../images/detail2.png'
import detail3 from '../images/detail3.png'
import detail4 from '../images/detail4.png'
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
  }, []);

  return (
    <>
      {/* Home Cover Image Section Starts */}
      <div className="home-page">
        <div className="cover-image">
          <div className="overlay"></div>
          <div className="overlay-content">
            <h2>Products that combine architecture, design & function</h2>
            <h5>
              <span>Together for better</span>
              <br />
              <br />
              Together we create sustainable buildings with high-quality
              aluminium solutions.
            </h5>
            <Link to='/products' ><button type="button">Discover More</button></Link>
          </div>
        </div>
      </div>
      <div className="home-details">
        <img src={detail1} alt="..." />
        <img src={detail2} alt="..." />
        <img src={detail3} alt="..." />
        <img src={detail4} alt="..." />
      </div>
      {/* Home Cover Image Section Ends */}

      {/* Home Product Categories Section Starts */}
      <div className="product-categories">
        <h4>Discover our innovative and sustainable aluminium products</h4>
        <div className="categories">
          <Link to='/products/window'>
          <div>
            <div>
              <div>
                <img src={window} alt="..." />
              </div>
            </div>
            <h5>Windows</h5>
          </div>
          </Link>
          <Link to='/products/door'>
          <div>
            <div>
              <div>
                <img src={door} alt="..." />
              </div>
            </div>
            <h5>Door</h5>
          </div>
          </Link>
          <Link to='/products/slidingandfolding'>
          <div>
            <div>
              <div>
                <img src={slidingFolding} alt="..." />
              </div>
            </div>
            <h5>Sliding and Foldings</h5>
          </div>
          </Link>
          <Link to='/products/facade'>
          <div>
            <div>
              <div>
                <img src={facade} alt="..." />
              </div>
            </div>
            <h5>Facade</h5>
          </div>
          </Link>
          <Link to='/products'>
          <div>
            <div>
              <div>
                <img src={MoreProducts} alt="..." />
              </div>
            </div>
            <h5>All Products</h5>
          </div>
          </Link>
        </div>
      </div>
      {/* Home Product Categories Section Ends */}

      {/* Home why Aluminium Section Starts */}
      <div className="why-aluminium">
        <h3>Why Aluminium?</h3>
        <p>
          The most important reasons to consider aluminium for your next project
        </p>
        <div className="aluminium-quality">
          <div>
            <img src={lifetime} alt="..." />
            <h6>Lifetime</h6>
          </div>
          <div>
            <img src={insulation} alt="..." />
            <h6>Insulation</h6>
          </div>
          <div>
            <img src={design} alt="..." />
            <h6>Design</h6>
          </div>
          <div>
            <img src={sustainability} alt="..." />
            <h6>Sustainability</h6>
          </div>
          <div>
            <img src={colours} alt="..." />
            <h6>Colours</h6>
          </div>
          <div>
            <img src={versatility} alt="..." />
            <h6>Versatility</h6>
          </div>
          <div>
            <img src={safety} alt="..." />
            <h6>Safety</h6>
          </div>
          <div>
            <img src={investment} alt="..." />
            <h6>Investment</h6>
          </div>
        </div>
        <Link to='/aluminium'><button type="button">Learn More</button></Link>
      </div>
      {/* Home why Aluminium Section Ends */}

      {/* Product Types Section Starts */}

      <div className="product-types">
        <div className="single-product-type">
          <div className="product-type-content">
            <h2>Windows</h2>
            <p>Prolumin manufactures and supplies a wide range of aluminium windows, which provide some of the best levels of security and energy efficiency available today. Whether you choose a casement window or a tilt or turn, you are always guaranteed high quality. Browse our product pages for more detailed information on all our types of windows.</p>
            <Link to='/products/window'><button type="button">View Range</button></Link>
          </div>
          <div className="product-type-image">
            <img src={productWindow} alt="..." />
          </div>
        </div>
        <div className="single-product-type">
          <div className="product-type-image">
            <img src={productDoor} alt="..." />
          </div>
          <div className="product-type-content">
            <h2>Doors</h2>
            <p>Prolumin manufacture and supply a variety of aluminium doors. From residential entrance doors to multi-sash bi-folds or heavy-duty traffic doors for commercial properties, we ensure our products are manufactured to the highest quality.</p>
            <Link to='/products/door'><button type="button">View Range</button></Link>
          </div>
        </div>
        <div className="single-product-type">
          <div className="product-type-content">
            <h2>Bench Mark Of Excellence</h2>
            <div>
              <ul>
                <li>State of Art Products & Manufacturing Facilities.</li>
                <li>360-Degree Customer Service through Nation Wide.</li>
                <li>Controls Entire Supply Chain from Extrusion to Installation.</li>
                <li>Trademark Quality Assurance with Excellent Functionality.</li>
                <li>Proud Members of Indian Green Building Council and First Company to be ROHS Compliant.</li>
                <li>Unmatched Execution Capabilities.</li>
                <li>Our Products are FREE from Lead & Hazardous Components</li>
              </ul>
            </div>
            {/* <button type="button">View Range</button> */}
          </div>
          <div className="product-type-image">
            <img src={productBenchMark} alt="..." />
          </div>
        </div>
      </div>

      {/* Product Types Section Ends */}

      {/* Our Products Slider Starts */}
      {/* <div className="product-slider-container">
        <h3>Our Products</h3>
        <ProductSlider />
      </div> */}

      {/* Our Products Slider Ends */}
    </>
  );
};

export default Home;
