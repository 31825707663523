import React from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'
import logo from '../images/logo.png'

const Footer = () => {
  return (
    <div className='footer'>
        <Link to='/'>
            <div className='company-title'>
                <img className='logo' src={logo} alt="..." />
            </div>
        </Link>
        <div className='footer-menu'>
            <div>
                <h5>Our Products</h5>
                <ul>
                    <li><Link to='/products/window'><p>Windows</p></Link></li>
                    <li><Link to='/products/door'><p>Doors</p></Link></li>
                    <li><Link to='/products/slidingandfolding'><p>Sliding And Folding</p></Link></li>
                    <li><Link to='/products/facade'><p>Facade</p></Link></li>
                </ul>
            </div>
            <div>
                <h5>Our Company</h5>
                <ul>
                    <li><Link to='/about'>About Us</Link></li>
                </ul>
            </div>
            <div>
                <h5>Contact</h5>
                <ul>
                    <li><Link to='/contact'>Contact Prolumin</Link></li>
                </ul>
            </div>
            <div>
                <h5>Follow Us on:</h5>
                <div>
                    <i className="fa-brands fa-facebook"></i>
                    <i className="fa-brands fa-instagram"></i>
                    <i className="fa-brands fa-youtube"></i>
                </div>
            </div>
        </div>
        <div className='footer-vision'>
            <p>Together with our partners, we focus on creating energy-efficient, responsibly manufactured products that make a difference to homes, buildings and the people they serve.</p>
        </div>
        <div className='footer-copyright'>
            <p>&copy;copyright 2023 @ Prolumin Group</p>
        </div>
    </div>
  )
}

export default Footer
