import React, { useEffect } from 'react'
import '../css/product.css'
import PageHead from './PageHead'
// import PageHead from './PageHead'
import {productSliding} from './ProductData'
import ProductDisplay from './ProductDisplay'
import headImage from '../images/Slide-Header.jpg'


const Window = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='product-window'>
      {/* <PageHead page='Sliding and Folding' /> */}
      <PageHead page='Sliding and Folding' content='Our sliding and folding window systems are designed to be easy to operate and offer excellent sealing characteristics, providing exceptional sound insulation and low heat transfer for maximum energy savings' image={headImage} />
      {
        productSliding.map((item)=>{
          return <ProductDisplay key={item.key} data={item}/>
        })
      }
    </div>
  )
}

export default Window
