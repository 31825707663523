import React from 'react'
import { Link } from 'react-router-dom'
import '../css/navbar.css'
import logo from '../images/logo.png'

const Navbar = () => {

  const showSmallMenu = (e) => {
    if(document.querySelector('.small-menu').style.display === 'block'){
      document.querySelector('.small-menu').style.display = 'none';
      document.querySelector('.menu-button>i').className  = 'fa-sharp fa-solid fa-bars';
    }else{
      document.querySelector('.small-menu').style.display = 'block';
      document.querySelector('.menu-button>i').className  = 'fa-solid fa-xmark';
    }
  }

  const showSmallSubmenu = (e) => {
    if(e.target.querySelector('.small-submenu').classList.contains('small-closed-menu')){
      e.target.querySelector('.small-submenu').classList.add('small-open-menu');
      e.target.querySelector('.small-submenu').classList.remove('small-closed-menu');
    }else{
      e.target.querySelector('.small-submenu').classList.remove('small-open-menu');
      e.target.querySelector('.small-submenu').classList.add('small-closed-menu');
    }
  }

  const hideMenu = () => {
    document.querySelector('.small-menu').style.display = 'none';
    document.querySelector('.menu-button>i').className  = 'fa-sharp fa-solid fa-bars';
  }

  return (
    <>
      <div className='header'>
        <Link to='/'>
          <div className='company-title'>
            <img className='logo' src={logo} alt="..." />
          </div>
        </Link>
        <div>
          <button className='menu-button' type='button' onClick={showSmallMenu}><i className="fa-sharp fa-solid fa-bars"></i></button>
        </div>
      </div>
      <div className='small-menu-container'>
        <ul className='small-menu'>
          <li onClick={showSmallSubmenu}>Products
            <ul className='small-submenu small-closed-menu'>
              <li><Link to='/products/window' onClick={hideMenu}><p>Windows</p></Link></li>
              <li><Link to='/products/door'  onClick={hideMenu}><p>Doors</p></Link></li>
              <li><Link to='/products/slidingandfolding'  onClick={hideMenu}><p>Sliding And Folding</p></Link></li>
              <li><Link to='/products/facade'  onClick={hideMenu}><p>Facade</p></Link></li>
              <li><Link to='/products'  onClick={hideMenu}><p>All Products</p></Link></li>
            </ul>
          </li>
          <li><Link to='/about'  onClick={hideMenu}><p>About Us</p></Link></li>
          <li><Link to='/contact'  onClick={hideMenu}><p>Contact Us</p></Link></li>
        </ul>
      </div>
    </>

  )
}

export default Navbar
