import React, { useEffect, useRef } from 'react'
import image1 from '../images/corousel_1.jpg'
import image2 from '../images/corousel_2.jpg'
import image3 from '../images/corousel_3.jpg'

const Corousel = () => {
    const nextBtn = useRef();

    useEffect(()=>{
        setTimeout(()=>{
            nextBtn.current.click();
        },3000)
    },[])


  return (
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="3000">
                <img src={image1} className="d-block w-100" alt="..." />
                <div className='gradient'></div>
                <div className="carousel-caption d-none d-md-block">
                    {/* <h5>First slide label</h5>
                    <p>Some representative placeholder content for the first slide.</p> */}
                </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
                <img src={image2} className="d-block w-100" alt="..." />
                <div className='gradient'></div>
                <div className="carousel-caption d-none d-md-block">
                    {/* <h5>Second slide label</h5>
                    <p>Some representative placeholder content for the second slide.</p> */}
                </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
                <img src={image3} className="d-block w-100" alt="..." />
                <div className='gradient'></div>
                <div className="carousel-caption d-none d-md-block">
                    {/* <h5>Third slide label</h5>
                    <p>Some representative placeholder content for the third slide.</p> */}
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button ref={nextBtn} className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
  )
}

export default Corousel
