import React, { useEffect } from 'react'
import '../css/product.css'
import PageHead from './PageHead'
// import PageHead from './PageHead'
import {productDoor} from './ProductData'
import ProductDisplay from './ProductDisplay'
import headImage from '../images/Doors-Header.jpg'


const Window = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='product-window'>
      {/* <PageHead page='Doors'/> */}
      <PageHead page='Doors' content='Prolumin Manufacture And Supply A Variety Of Aluminium Doors.' image={headImage} />
      {
        productDoor.map((item)=>{
          return <ProductDisplay key={item.key} data={item}/>
        })
      }
    </div>
  )
}

export default Window
