import React, { useEffect } from 'react'
import '../css/product.css'
import PageHead from './PageHead'
// import PageHead from './PageHead'
import {productWindow} from './ProductData'
import ProductDisplay from './ProductDisplay'
import headImage from '../images/Window-Header.jpg'


const Window = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='product-window'>
      {/* <PageHead page='Windows' /> */}
      <PageHead page='Windows' content='Prolumin Manufacture And Supply And Wide Range Of Aluminium Windows' image={headImage} />
      {
        productWindow.map((item)=>{
          return <ProductDisplay key={item.key} data={item}/>
        })
      }
    </div>
  )
}

export default Window
