import React, { useEffect } from 'react'
import '../css/product.css'
import PageHead from './PageHead'
// import PageHead from './PageHead'
import {productFacade} from './ProductData'
import ProductDisplay from './ProductDisplay'
import headImage from '../images/Facade-Header.jpg'


const Window = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div className='product-window'>
      {/* <PageHead page='Facade' /> */}
      <PageHead page='Facade' content='Prolumin specialize in the design, manufacture, and supply of high-quality facade solutions for commercial and residential buildings' image={headImage} />
      {
        productFacade.map((item)=>{
          return <ProductDisplay key={item.key} data={item}/>
        })
      }
    </div>
  )
}

export default Window
