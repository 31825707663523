import React from 'react'
import '../css/floating.css'
// import pdfFile from '../components/PROLUMIN_VOL1_.pdf'

const FloatingOption = () => {
  return (
    <div className='floating-options'>
      {/* <div className='option'>
        <a  href={pdfFile} download='Prolumin'>
            <i className="fa-solid fa-download"></i>
            <span>Download Our Brochure</span>
        </a>
      </div> */}
    </div>
  )
}

export default FloatingOption
