import React from 'react'
import { Link } from 'react-router-dom';
import '../css/sidebar.css'

const Sidebar = () => {

  const showMenu = (e) => {
    if(e.target.nextElementSibling === document.querySelector('.submenu')){
      // if(e.target.querySelector('.submenu').classList.contains('closed-menu')){
      //   e.target.querySelector('.submenu').classList.add('open-menu');
      //   e.target.querySelector('.submenu').classList.remove('closed-menu');
      // }else{
      //   e.target.querySelector('.submenu').classList.remove('open-menu');
      //   e.target.querySelector('.submenu').classList.add('closed-menu');
      // }
      if(e.target.nextElementSibling.classList.contains('closed-menu')){
        e.target.parentElement.classList.add('active-menu');
        e.target.nextElementSibling.classList.add('open-menu');
        e.target.nextElementSibling.classList.remove('closed-menu');
      }else{
        e.target.parentElement.classList.remove('active-menu');
        e.target.nextElementSibling.classList.remove('open-menu');
        e.target.nextElementSibling.classList.add('closed-menu');
      }
    }
  }

  document.addEventListener('click', (e)=>{
    if(e.target !== document.querySelector('.menu>li') && e.target !== document.querySelector('.menu>li>p') && e.target !== document.querySelector('.submenu') && e.target.parentElement !== document.querySelector('.submenu')){
      document.querySelector('.submenu').parentElement.classList.remove('active-menu');
      document.querySelector('.submenu').classList.remove('open-menu');
      document.querySelector('.submenu').classList.add('closed-menu');
    }
  })

  return (
    <div className='aside'>
      {/* <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa-solid fa-magnifying-glass"></i></button>
      </div> */}
      <div className='menu-container'>
        <ul className='menu'>
          <li onClick={showMenu}><p>Products</p>
            <ul className='submenu closed-menu'>
              <li><Link to='/products/window'><p>Windows</p></Link></li>
              <li><Link to='/products/door'><p>Doors</p></Link></li>
              <li><Link to='/products/slidingandfolding'><p>Sliding And Folding</p></Link></li>
              <li><Link to='/products/facade'><p>Facade</p></Link></li>
              <li><Link to='/products'><p>Browse All Products</p></Link></li>
            </ul>
          </li>
          <li><Link to='/about'><p>About Us</p></Link></li>
          <li><Link to='/contact'><p>Contact Us</p></Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
