import React from 'react'
// import pdfFile from '../components/PROLUMIN_VOL1_.pdf'

const ProductDisplay = (props) => {
  const {data} = props;

  // const handleFeatures = (e, element) => {
  //   let featureContainer = e.target.parentElement.nextElementSibling.children;
  //   let featureBtns = e.target.parentElement.children;
  //   for(let i = 0; i< featureContainer.length; i++){
  //     featureContainer[i].classList.add('d-none');
  //     featureBtns[i].classList.remove('active');
  //   }
  //   featureContainer[element].classList.remove('d-none');
  //   featureBtns[element].classList.add('active');
  // }

  return (
    <div className='single-product'>
        <div className='product-image'>
          <img src={data.image} alt='...' />
          <div className='product-gradient'></div>
          <div className='product-name'>
            <h1>{data.name}</h1>
          </div>
        </div>
        <div className='product-description'>
          <p>{data.desc}</p>
          <div className='features-btns'>
            {/* {
                data.buttons.map((item,i)=>{
                    return <button type='button' key={i} className={i === 0? 'active' : ''} onClick={(e)=>{handleFeatures(e,i)}}>{item}</button>
                })
            } */}
          </div>
          {/* <div className='product-features-container'>
            {
                data.productFeatures.map((item,i)=>{
                    return <div key={i} className={i === 0? 'product-features' : 'product-features d-none'}>
                        <div className='product-feature-image'>
                            <img src={item.featureImage} alt='...' />
                        </div>
                        <div className='product-feature-features'>
                            <h5>Features</h5>
                            <table>
                            <tbody>
                                {
                                    item.features.map((itemFeature, i)=>{
                                        return <tr key={i}>
                                            <td><h6>{itemFeature.featureName}</h6></td>
                                            <td>: {itemFeature.featureValue}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            </table>
                        </div>
                    </div>
                })
            }
          </div> */}
          {/* <a href={pdfFile} download='Prolumin'><button type='button' className='download-pdf'><i className="fa-solid fa-download mx-2"></i>Download Product Brochure</button></a> */}
        </div>
      </div>
  )
}

export default ProductDisplay
