import { Route, Routes } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import './css/main.css'
import LoadingBar from 'react-top-loading-bar';
import Alert from './components/Alert';
import { useState } from 'react';
import Products from './components/Products';
import Window from './components/Window';
import SlidingAndFolding from './components/SlidingAndFolding';
import Facade from './components/Facade';
import Door from './components/Door';
import WhyAluminum from './components/WhyAluminum';
import FloatingOption from './components/FloatingOption';


function App() {
  const [progress, setProgress] = useState(0); 
  const [alert, setAlert] = useState(null); 

  const showAlert = (message, type) => {
    setAlert({
        msg: message,
        type: type
    })
  }


  return (
    <div className="main-section">
      <div id='navbar'>
        <Navbar/>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={()=>{setProgress(0)}}
          height = {3}
        />
        <Alert alert={alert} setAlert = {setAlert}/>
      </div>
      <div id='sidebar'>
        <Sidebar/>
      </div>
      <div id='section'>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact  showAlert={showAlert} setProgress={setProgress} />} />
          <Route exact path='/aluminium' element={<WhyAluminum />} />
          <Route exact path='/products' element={<Products />} />
          <Route exact path='/products/window' element={<Window />} />
          <Route exact path='/products/slidingandfolding' element={<SlidingAndFolding />} />
          <Route exact path='/products/facade' element={<Facade />} />
          <Route exact path='/products/door' element={<Door />} />
        </Routes>
        <Footer/>
      </div>
      <FloatingOption />
    </div>
  );
}

export default App;
