import React, { useEffect, useRef, useState } from 'react'
// import PageHead from './PageHead'
import '../css/contact.css'
import emailjs from '@emailjs/browser';

const Contact = (props) => {
  const form = useRef();
  const [office, setOffice] = useState('gurgaon');
  const [data, setData] = useState({user_name: "", user_email:"", user_contact: "", user_state : "", user_city : "", message: ""});

  const changeOffice = (loc) => {
    setOffice(loc);
  }

  const handleEmail = (e) => {
    props.setProgress(10);
    e.preventDefault();
    props.setProgress(30);
    emailjs.sendForm('service_cslgydr', 'template_2xeqvq9', form.current, '-BiJflNl_xlSKefZC')
    .then(() => {
      props.setProgress(100);
      props.showAlert("Thank You for contacting Prolumin. We'll reach back to you as soon as possible.", "success");
    }, () => {
      props.setProgress(100);
      props.showAlert("Internal Server Error!! Message Not Sent!!", "danger");
    });
    console.log(data);
    setData({user_name: "", user_email:"", user_contact: "", message: ""});
};

  const handleChange = (e) => {
    setData({...data, [e.target.name] : e.target.value})
  }

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div>
      {/* <PageHead page='Contact Us'/> */}
      <div className='maps'>
        <div className='office-map-buttons'>
          <button type='button' onClick={()=>{changeOffice('gurgaon')}} style={office === 'gurgaon'?{backgroundColor: 'var(--white)', color: 'var(--blue)'}:{backgroundColor: 'var(--blue)', color: 'var(--white)'}}>Gurgaon Office</button>
          <button type='button' onClick={()=>{changeOffice('amritsar')}} style={office === 'amritsar'?{backgroundColor: 'var(--white)', color: 'var(--blue)'}:{backgroundColor: 'var(--blue)', color: 'var(--white)'}}>Amritsar Office</button>
        </div>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.0827541592125!2d77.07343734793362!3d28.44972997837705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18eb39c73b7f%3A0xfa2bf465e3d3d800!2s145%2C%20Sector%2044%20Rd%2C%20Sector%2044%2C%20Gurugram%2C%20Haryana%20122003!5e0!3m2!1sen!2sin!4v1675351898763!5m2!1sen!2sin" title='gurgaon' style={office === 'gurgaon'?{}:{display:'none'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.463321058692!2d74.87735201512189!3d31.648541581327386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919635e7e630403%3A0xbc60ca4eff640dab!2sGanpati%20Towers%2C%20Lawrence%20Road%2C%20Dayanand%20Nagar%2C%20Amritsar%2C%20Punjab%20143104!5e0!3m2!1sen!2sin!4v1675355033475!5m2!1sen!2sin" title='amritsar' style={office === 'amritsar'?{}:{display:'none'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className='contact-form'>
        <div>
          <form  ref={form} onSubmit={handleEmail}>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" name='user_name' onChange={handleChange} value={data.user_name} placeholder="Enter Name" required/>
              <label htmlFor="floatingInput">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="floatingInput" name='user_email' onChange={handleChange} value={data.user_email} placeholder="Enter Email" />
              <label htmlFor="floatingInput">Email</label>
            </div>
            <div className="form-floating mb-3">
              <input type="number" className="form-control" id="floatingInput" name='user_contact' onChange={handleChange} value={data.user_contact} placeholder="Enter Contact" required/>
              <label htmlFor="floatingInput">Phone</label>
            </div>
            <div className="form-floating mb-3">
              <select className="form-select" id="floatingSelect" name='user_state' onChange={handleChange} value={data.user_state} aria-label="Floating label select example" required>
                <option value="">Choose State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujrat">Gujrat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
              <label htmlFor="floatingSelect">State</label>
            </div>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" name='user_city' onChange={handleChange} value={data.user_city} placeholder="Enter City" required/>
              <label htmlFor="floatingInput">City</label>
            </div>
            <div className="form-floating">
              <textarea className="form-control" placeholder="Enter Message" name='message' onChange={handleChange} value={data.message} id="floatingTextarea2" required></textarea>
              <label htmlFor="floatingTextarea2">Message</label>
            </div>
            <div className='email-submit-btn'>
              <button type='submit'>Send Message<i className="fa-solid fa-paper-plane mx-2"></i></button>
            </div>
          </form>
        </div>
        <div className='contact-data'>
          <div>
            <i className="fa-sharp fa-solid fa-location-dot"></i>
            <div>
              <h4>Address</h4>
              <p><b>Gurgaon : </b>Building No. 145, Sector 44, Gurugram, Haryana 122003 <br/><b>Amritsar : </b>Ganpati Towers, 209-210, Lawrence Road, Amritsar, Punjab 143001</p>
            </div>
          </div>
          <div>
            <i className="fa-solid fa-phone"></i>
            <div>
              <h4>Contact</h4>
              <p><b>Gurgaon : </b>+91 9818120505<br/><b>Amritsar : </b>+91 9872949521, 8838939909</p>
            </div>
          </div>
          <div>
            <i className="fa-solid fa-envelope"></i>
            <div>
              <h4>Email</h4>
              <p>info@prolumin.co.in</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
